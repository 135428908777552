:root {
  /* Colores */

  --body-color: #f8f8f8;
  --sidebar-color: #fff;
  --primary-color: #ec933f;
  --primary-color-ligth: #f3f3f3;
  --secundary-color: #478673;
  --secundary-color-ligth: #f5f5f5;
  --text-color: #092f47;
  --toggle-color: rgb(214, 214, 214);
  --nav-color: rgb(239, 239, 239);
}

.row {
  margin: 0 !important;
}

.col-md-12 {
  padding: 0;
}

.version {
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading h4 {
  color: var(--primary-color-ligth);
  background: var(--primary-color);
  padding: 4px 15px 4px 15px;
  border-radius: 4px;
  font-weight: 400;
  text-transform: uppercase;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

/* TABLE */

.table {
  background: var(--sidebar-color);
  color: var(--text-color);
}

/*  LOGIN SCREEN */

.LoginHome {
  background: #eae8e8;
  padding: 40px;
}

.containerHome {
  min-height: calc(100vh - 40px * 2);
  display: grid;
  grid-template-columns: 40% 60%;
  border-radius: 10px;
  overflow: hidden;
}

.login-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.login-right {
  background-color: #eee;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px #232931;
}

.login-right img {
  width: 100%;
}

.login-header {
  margin-bottom: 40px;
  text-align: center;
  width: 80%;
}

.login-header img {
  height: 80px;
}

.login-header h1 {
  font-size: 25px;
  margin-bottom: 10px;
  letter-spacing: 3px;
  font-weight: 400;
  text-transform: uppercase;
}

.login-header p {
  opacity: 0.7;
}

.login-form {
  width: 90%;
}

input[type="text"],
input[type="password"] {
  border: 1px solid black;
  height: 45px;
  padding: 0 2rem;
  width: 100%;
  outline: none;
  transition: background 0.5s;
  font-size: 18px;
  border-radius: 100px;
}

input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

input[type="text"],
input[type="password"]:focus {
  background: white !important;
  font-size: 14px;
}

.homeLogin {
  width: 100%;
  border: none;
  border-radius: 100px;
  height: 40px;
  background: var(--primary-color);
  color: white;
  transition: all 0.3s ease;
}

.homeLogin:hover {
  cursor: pointer;
  background: var(--secundary-color);
}

.form-item label:not(.checkboxLabel) {
  display: inline-block;
  background-color: white;
  margin-bottom: 10px;
  position: absolute;
  padding: 0 10px;
  transform: translate(30px, -10px);
  font-size: 14px;
}

@media (max-width: 950px) {
  .containerHome {
    min-height: calc(100vh - 40px * 2);
    display: grid;
    grid-template-columns: 60% 40%;
    border-radius: 10px;
    overflow: hidden;
  }

  .login-right img {
    height: 100%;
  }
}

@media (max-width: 800px) {
  .containerHome {
    min-height: calc(100vh - 40px * 2);
    display: grid;
    grid-template-columns: 100%;
    border-radius: 10px;
    overflow: hidden;
  }

  .login-right {
    display: none;
  }
  .login-header {
    margin-bottom: 40px;
    text-align: center;
  }
}

/* Sidebar */

/* Reusable css */
.sidebar .text {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  white-space: nowrap;
  opacity: 1;
}

.sidebar .image {
  min-width: 60px;
  display: flex;
  align-items: center;
}

/* Sidebar */

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: var(--sidebar-color);
  transition: all 0.3s ease;
  z-index: 100;
}

.sidebar.cls {
  width: 88px;
}

.sidebar.cls .text {
  opacity: 0;
}

.sidebar li {
  margin-bottom: 10px;
  height: 50px;
  padding: 5px;
  margin: 5px;
  list-style: none;
  display: flex;
  align-items: center;
}

.sidebar li .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  font-size: 20px;
}

.sidebar li .icon,
.sidebar li .text {
  color: var(--text-color);
  transition: all 0.3s ease;
}

.sidebar ul {
  padding: 0;
  margin-top: 5px;
}

.sidebar header {
  position: relative;
}

.sidebar .image-text img {
  width: 70px;
  border-radius: 6px;
}

.image {
  margin-left: 14px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.sidebar header .image-text {
  display: flex;
  align-items: center;
}

header {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 4px solid var(--toggle-color);
}

.toggle {
  cursor: pointer;
}

header .image-text .header-text {
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.header-text .name {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--primary-color);
}

.header-text .subtitle {
  font-size: 12px;
  font-weight: 300;
  text-transform: capitalize;
  margin-top: 5px;
}

.sidebar header .toggle {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  height: 25px;
  width: 25px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
}

.sidebar li a {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s ease;
}

.sidebar li a:hover {
  background: var(--primary-color);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
  color: var(--primary-color-ligth);
}

.nav-link {
  padding: 0;
}

.sidebar .menu-bar {
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-bar .mode {
  position: relative;
  background: var(--toggle-color);
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
}

.menu-bar .mode .moon-sun {
  height: 50px;
  width: 60px;
  display: flex;
  align-items: center;
}

.menu-bar .mode i {
  position: absolute;
}

.menu-bar .mode i.sun {
  opacity: 0;
}

.menu-bar .mode .toggle-switch {
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 60px;
  cursor: pointer;
}

.toggle-switch .switch {
  position: relative;
  height: 22px;
  width: 44px;
  border-radius: 25px;
  background: rgb(126, 126, 126);
}

.switch::before {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  background: white;
  transition: all 0.3s ease;
}

body {
  background-image: url("./img/ocean.jpg") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  transition: all 0.3s ease;
}

body.dark {
  /* Colores */

  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-ligth: #cacaca;
  --toggle-color: #535353;
  --text-color: #ffffff;
  --nav-color: #686868;
  --secundary-color: #4b4c4e;
  --secundary-color-ligth: #cacaca;
}

body.dark .switch::before {
  left: 25px;
}

.home {
  min-height: 100vh;
  position: relative;
  padding: 20px;
  width: calc(100% - 250px);
  left: 250px;
  transition: all 0.3s ease;
}

.sidebar.cls ~ .home {
  width: calc(100% - 88px);
  left: 88px;
}

.buttonPrimary {
  width: 100%;
  padding: 10px;
  background: var(--primary-color);
  color: var(--primary-color-ligth);
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
}

.buttonPrimary:hover {
  background-color: var(--secundary-color);
}

.navCentro {
  text-decoration: none;
}

.breadcrumb {
  margin: 0;
  border-radius: 0px;
  background: var(--primary-color);
  color: var(--primary-color-ligth);
  border-radius: 15px;
  margin-bottom: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.navTab {
  background: var(--nav-color);
  text-align: center;
  border-radius: 15px;
  margin-top: 10px;
  margin-bottom: 10px !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.navTabItem {
  padding: 0;
}

.navTabItem a {
  text-decoration: none;
  color: var(--text-color);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: 500;
  border-left: 1px solid var(--toggle-color);
  border-right: 1px solid var(--toggle-color);
}

.navTabItem a:hover {
  background: var(--secundary-color);
  color: var(--secundary-color-ligth);
}

.title {
  background: var(--secundary-color);
  color: var(--secundary-color-ligth);
  padding: 10px;
  text-align: center;
  justify-content: center;
  margin-top: 30px;
}

.formFormat1 {
  display: flex;
  flex-direction: column;
  padding: 40px;
  color: var(--text-color);
  background: var(--sidebar-color);
}

.formFormat1 label {
  text-transform: uppercase;
}

.formFormat1 input {
  border-radius: 0px;
  margin-bottom: 20px;
  padding: 4px;
  height: 40px;
  border: none;
  border-bottom: 2px solid var(--secundary-color);
  background: white !important;
}

.inputTextArea {
  border: none;
  border-bottom: 2px solid var(--secundary-color);
}

.formFormat1 input:focus {
  border-bottom: 4px solid var(--primary-color);
}

.formFormat1 select {
  height: 30px;
  border: none;
  font-size: 15px;
  margin-bottom: 20px;
}

.formButton1 {
  background: var(--secundary-color);
  border: none;
  color: var(--primary-color-ligth);
  cursor: pointer;
  padding: 10px;
}

.formButton1:hover {
  background: var(--primary-color);
}

.guardar-asignacion-naves {
  background: var(--secundary-color);
  color: var(--secundary-color-ligth);
  border: none;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  transition: all 0.3s ease;
}

.guardar-asignacion-naves:hover {
  background-color: var(--primary-color);
  cursor: pointer;
  color: var(--primary-color-ligth);
}

.btn-red {
  background: rgb(210, 56, 56);
  color: white;
  border: none;
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  transition: all 0.3s ease;
}

.btn-red:hover {
  background-color: var(--primary-color);
  cursor: pointer;
  color: var(--primary-color-ligth);
}

.nave {
  background: var(--body-color);
  color: var(--text-color);
  padding: 10px;
  padding-left: 40px;
  font-size: 15px;
  transition: all 0.3s ease;
}

.nave i {
  font-size: 20px;
  margin-right: 10px;
  color: rgb(226, 74, 74);
}

/* 
.checkNave{

} */

.switchN {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 26px;
}

.switchN input {
  display: none;
}

.switchN .text {
  position: relative;
  margin-left: 80px;
  display: flex;
  width: 200px;
  font-size: 14px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 14px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(33px);
}

.selectedUser {
  background: var(--secundary-color);
  transition: all 0.3s ease;
  color: var(--primary-color-ligth);
}

.formSolicitud {
  padding: 20px;
  background: var(--primary-color-ligth);
  border-radius: 15px;
}

.buttonSolicitud {
  background: var(--secundary-color);
  color: var(--primary-color-ligth);
  width: 100%;
  padding: 10px;
  border: none;
  margin-top: 10px;
}

.buttonSolicitud:hover {
  cursor: pointer;
}

.buttonComment {
  background: rgb(56, 56, 161);
  color: var(--primary-color-ligth);
  width: 100%;
  padding: 10px;
  border: none;
  margin-top: 10px;
}

.buttonComment:hover {
  cursor: pointer;
}

.buttonSolicitudRechazar {
  background: rgb(219, 70, 70);
  color: var(--primary-color-ligth);
  width: 100%;
  padding: 10px;
  border: none;
  margin-top: 20px;
}

.buttonSolicitudRechazar:hover {
  cursor: pointer;
}

.inputFormGest {
  border: none;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
}

.delete-button {
  background-color: #dc3545; /* red background color */
  color: #fff; /* white text color */
  border: none; /* remove border */
  padding: 8px 16px; /* add some padding */
  border-radius: 4px; /* add rounded corners */
  cursor: pointer; /* change cursor to pointer on hover */
}

.delete-button:hover {
  background-color: #c82333; /* darker red background color on hover */
}

.label-white {
  background: var(--sidebar-color);
  padding: 5px;
  color: var(--text-color);
  height: 30px;
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
}

.label-title {
  font-size: 10px;
  margin: 0;
  color: var(--primary-color);
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.header {
  padding: 20px;
  background: var(--primary-color-ligth);
}

.header-name {
  padding-top: 20px;
  width: 100%;
}

.item-card {
  width: 100%;
  background: rgb(233, 233, 233);
  padding: 4px;
  border-bottom: 1px solid rgb(203, 203, 203);
  margin-bottom: 2px;
  cursor: pointer;
}

.btn-abierta {
  background-color: rgb(161, 161, 161);
  padding: 4px 15px 4px 15px;
  color: white;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-rechazada {
  background-color: rgb(206, 80, 80);
  padding: 4px 15px 4px 15px;
  color: white;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-aprobada {
  background-color: rgb(29, 157, 51);
  padding: 4px 15px 4px 15px;
  color: white;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-gestion {
  background-color: rgb(97, 31, 113);
  padding: 4px 15px 4px 15px;
  color: white;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-programada {
  background-color: rgb(255, 241, 43);
  padding: 4px 15px 4px 15px;
  color: rgb(8, 8, 8);
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-garantia {
  background-color: rgb(146, 236, 227);
  padding: 4px 15px 4px 15px;
  color: rgb(8, 8, 8);
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-conforme {
  background-color: rgb(121, 226, 142);
  padding: 4px 15px 4px 15px;
  color: black;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-finalizado {
  background-color: rgb(121, 52, 14);
  padding: 4px 15px 4px 15px;
  color: white;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.btn-trabajos {
  background-color: rgb(253, 131, 0);
  padding: 4px 15px 4px 15px;
  color: white;
  border-radius: 3px;
  width: 100px;
  border: none;
}

.col-md-12 {
  overflow: hidden;
}

.filtros {
  width: 100%;
  background: var(--body-color);
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.filtros input {
  margin-left: 5px;
  max-width: 130px;
  margin-top: 10px;
  border-radius: 15px;
  border: none;
  background: white;
  padding-left: 10px;
}

@media (max-width: 800px) {
  /* .sidebar{
    display: none;
  } */
  .home {
    min-height: 100vh;
    position: relative;
    padding: 20px;
    width: 100%;
    left: 0;
    transition: all 0.3s ease;
  }
  .navTabItem a {
    font-size: 12px;
  }

  .navbar-responsive ul {
    list-style: none;
    display: flex;
    text-align: center;
    padding: 0;
  }

  .navbar-responsive ul li {
    margin-right: 10px;
    align-content: center;
    margin: auto;
  }

  .logo-li {
    width: 30px !important;
  }

  .navbar-responsive ul li img {
    width: 50px;
  }

  .nav-link {
    background: rgb(234, 234, 234);
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .nav-link a,
  .nav-link a i {
    color: var(--text-color);
  }

  .nav-link a i {
    padding-left: 15px;
    margin-right: 4px;
  }
}

.modal-naves {
  position: fixed;
  background: rgba(6, 6, 6, 0.746);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  padding: 10px;
}

.image-container {
  width: 100%; /* Set the desired width of the container */
  height: 300px; /* Set the desired height of the container */
  border: 1px solid #ccc; /* Optional: Add a border to the container for visualization */

  /* Set the container as a flex container to center the image */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  /* Set the image to cover the entire container without distorting the aspect ratio */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 
.header-img{
  background: rgb(196, 196, 196);
  height: 350px;
  width: 100%;
} */
.header-img {
  width: 100%;
  height: 350px; /* Set the desired height of the container */
  border: 1px solid #ccc; /* Optional: Add a border to the container for visualization */

  /* Set the container as a flex container to center the image */
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-img img {
  /* Set the image to cover the entire container without distorting the aspect ratio */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hide the input file element */
/* input[type="file"] {
  display: none;
} */

.file-upload {
  display: inline-block;
  cursor: pointer;
  /* background-color: #007bff; */
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.file_name {
  border-radius: 0px !important;
  padding: 0px !important;
  height: 34px !important;
}

.file-upload span {
  margin-right: 10px;
}

/* Optional: Add hover and focus styles to make it more interactive */
.file-upload:hover,
.file-upload:focus {
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 0 0 4px rgba(0, 123, 255, 0.3);
}

.deleteImg {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(228, 65, 65);
  padding: 10px;
  color: white;
}

.upload-button {
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  background: #ffffff;
  width: 100%;
  padding: 5px;
}

.file-uploader {
  width: 100%;
  padding: 10px;
  background: white;
  position: relative;
}

.foto {
  position: fixed;
  cursor: pointer;
  width: 100%;
  padding: 30px;
  align-items: c;
  height: 100%;
  background: #6c6c6ca9;
  top: 0;
  z-index: 10000;
}

.camera {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 90%;
  z-index: 10001;
  height: 80%;
  background: rgb(247, 246, 246);
}

.video {
  position: relative;
  /* background: #808080; */
  height: 400px;
  width: 80%;
  z-index: 10003;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imgDefault {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 10px;
  background: rgb(228, 228, 228);
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 50px;
  color: #478673;
}

.list-archivos {
  list-style: none;
  padding: 0;
}

.list-archivos li {
  background: var(--sidebar-color);
  margin-top: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  padding: 5px;
}

.archivos {
  color: var(--secundary-color);
  font-size: 12px;
}

.table-rep {
  width: 100%;
  background: white;
  border-collapse: collapse;
  border-radius: 5px;
}

.table-rep th {
  background-color: white;
  color: #333;
  font-weight: bold;
  font-size: 15px;
  padding: 5px;
  text-align: left;
  border-radius: 5px;
}

.table-rep td {
  background-color: white;
  color: #333;
  font-size: 12px;
  padding: 5px;
  text-align: left;
  border-radius: 5px;
}

.btn-list {
  width: 60px;
  color: white;
  font-size: 12px;
}

@media (max-width: 800px) {
  .imgDefault {
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
    border-radius: 10px;
    background: rgb(228, 228, 228);
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 40px;
    color: #478673;
  }
}

.titleInput {
  width: 100%;
  border: none;
  border-bottom: 1px solid rgb(194, 194, 194);
  padding: 4px;
  transition: all 0.3s ease;
}

.titleInput:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-color);
}

.selectInput {
  width: 50%;
  border: 0px solid white;
  padding: 8px;
}

.textAreaInput {
  height: 70px;
  width: 100%;
  border: none;
  padding: 4px;
}

.textAreaInput:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-color);
}

.cardFormComentarios {
  padding: 10px;
  background: white;
  border-radius: 10px;
}

.title-section {
  background: var(--secundary-color);
  padding: 5px;
  width: 100%;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--secundary-color-ligth);
}

.comentarios {
  padding: 5px;
  margin-bottom: 8px;
  max-height: 400px;
  overflow: scroll;
}

.comentario-body {
  background: white;
  border-radius: 10px;
  font-size: 12px;
  padding: 5px;
  margin-bottom: 8px;
}

.header-comentario {
  font-size: 14px;
  background: white;
  border-bottom: 1px solid rgb(184, 184, 184);
}

.header-comentario ul {
  list-style-type: none;
  margin: 0;
  padding: 5px;
}

.header-comentario ul li {
  display: inline-block;
  margin-left: 5px;
}

.userLi {
  color: var(--primary-color);
  font-weight: 600;
}

.tipoComentarioLi {
  background: rgb(35, 91, 224);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 4px;
  margin-left: 30px;
  font-size: 12px;
  width: 100px;
  text-align: center;
}

.tipoComentarioLi2 {
  background: rgb(68, 147, 71);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 4px;
  margin-left: 30px;
  font-size: 12px;
  width: 100px;
  text-align: center;
}

.body-comentario {
  background: white;
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 10px;
}

.userList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.userLi {
  margin-right: auto;
  padding-right: 10px; /* Add some spacing between items */
}

.userLiContainer {
  display: inline-block;
  flex-direction: column;
  align-items: flex-end;
}

.borarComent {
  background: rgb(236, 68, 68);
  color: white;
  border: none;
  font-size: 15px;
  border-radius: 5px;
}

.borarComent:hover {
  cursor: pointer;
}

.drop-container {
  border: 2px dashed #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.dropped-item {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 5px;
  margin: 5px;
}

.drag-container {
  display: flex;
}

.draggable-item {
  background-color: #e0e0e0;
  border: 1px solid #ccc;
  padding: 5px;
  margin-right: 10px;
}

.inputImages {
  padding: 20px;
  background: white;
  margin: 20px;
}

.inputFileImages {
  display: block !important;
  width: 100%;
  margin-bottom: 20px;
}

.fileUploadButton {
  width: 100%;
  border: none;
  padding: 5px;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  background: rgb(41, 41, 123);
}

.imageUpload {
  height: 300px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.nivelModal {
  background: rgba(12, 12, 12, 0.436);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  cursor: pointer;
}

.nivelModal-card {
  background: rgb(239, 239, 239);
  width: 50%;
  height: 80%;
  position: fixed;
  z-index: 10001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
}

.inputTable {
  width: 100%;
  background: rgb(248, 248, 248);
  border: none;
  padding: 5px;
}

.inputTable:focus {
  outline: none;
}

.emailContainer {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  top: 0;
  z-index: 100000;
}

.header-modal {
  font-size: 20px;
  font-weight: 500;
}

.textAreaInput2 {
  height: 40px;
  width: 100%;
  border: none;
  padding: 4px;
}

.textAreaInput2:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-color);
}

.footer-modal {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  gap: 10px; /* Add gap between items */
}

.container-modal {
  background: rgba(12, 12, 12, 0.436);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  cursor: pointer;
}

.body-modal {
  background: rgb(239, 239, 239);
  width: 80%;
  height: auto;
  max-height: 90vh;
  overflow-y: scroll;
  position: fixed;
  z-index: 10001;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
}

.filtroActivosSistemas {
  width: 100%;
  margin-bottom: 10px;
  padding: 4px;
  border-radius: 5px;
  border: 2px solid var(--primary-color);
}

.checkbox-group {
  display: flex;
  gap: 10px;
}
.checkbox-group label {
  display: flex;
  align-items: center;
}

.content {
  padding: 20px;
  background: var(--primary-color-ligth);
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.headerContent {
  display: flex;
  justify-content: space-between;
}

.buttonContentPrimary {
  border: none;
  background: var(--secundary-color);
  color: var(--primary-color-ligth);
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  padding: 0 20px 0 20px;
}

.buttonContentPrimary:hover {
  background-color: var(--primary-color-ligth);
  color: var(--primary-color);
  cursor: pointer;
}

.buttonContentSecondary {
  border: none;
  background-color: var(--primary-color-ligth);
  color: var(--primary-color);
  height: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  padding: 0 20px 0 20px;
}

.buttonContentSecondary:hover {
  background: var(--secundary-color);
  color: var(--primary-color-ligth);
  cursor: pointer;
}

.titleContent {
  color: var(--primary-color);
  font-size: 35px;
  font-weight: 400;
}

.subtitleContent {
  color: var(--primary-color);
  font-size: 25px;
  font-weight: 400;
}

.cardContent {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  background: var(--body-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  margin: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardContent:hover {
  cursor: pointer;
}

.horometro {
  color: var(--primary-color-ligth);
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
  background: var(--secundary-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.fuelContent {
  color: var(--primary-color);
  font-size: 20px;
  margin: auto;
}

.card-registros {
  width: 99%;
  margin: auto;
  background: var(--body-color);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;
  margin-top: 10px;
}

.card-registros:hover {
  width: 100%;
}

.card-registros-title {
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust the height as needed */
  text-align: center;
}

.item-activo-registros {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white;
  margin-bottom: 5px;
  border-radius: 15px;
}

.item-activo-registros span {
  font-size: 12px;
  font-weight: 600;
}

.item-activo-registros button {
  background: var(--secundary-color);
  color: var(--primary-color-ligth);
  border: none;
  padding: 0px 15px 0px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.item-activo-registros button:hover {
  background: var(--primary-color);
}

.inputRegistros {
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

.backButton {
  background: var(--primary-color-ligth);
  color: var(--primary-color);
  border: none;
  padding: 0px 15px 0px 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.backButton:hover {
  background: var(--primary-color);
  color: var(--primary-color-ligth);
}
